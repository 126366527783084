import React, {Component} from 'react';
import './home.css';

import {
  Header,
  Submit,
  StepLabel
} from '../../components';

export default class ViewHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "selected_car_type": null
    }
  }

  componentDidMount() {

  }

  selectCarType(type) {
    this.setState({
      "selected_car_type": type
    })
  }

  onSubmit() {
    window.location = `${window.location.origin}/#/washes/${this.state.selected_car_type}`;
  }

  render() {
    return (<div className="lavery-view-home">
      <Header/>
      <StepLabel
        step={1}
        label="Selecione o tipo do seu veículo"
      />
      <ul className="lavery-view-home-categories">
        <li className={`lavery-view-home-car mini ${this.state.selected_car_type === "mini" ? "active" : ""}`} onClick={() => this.selectCarType("mini")}>
          <span className="lavery-view-home-car-icon"></span>
          <span className="lavery-view-home-car-title">Carro Mini</span>
          <span className="lavery-view-home-car-checked"></span>
        </li>
        <li className={`lavery-view-home-car pequeno ${this.state.selected_car_type === "pequeno" ? "active" : ""}`} onClick={() => this.selectCarType("pequeno")}>
          <span className="lavery-view-home-car-icon"></span>
          <span className="lavery-view-home-car-title">Carro Pequeno</span>
          <span className="lavery-view-home-car-checked"></span>
        </li>
        <li className={`lavery-view-home-car medio ${this.state.selected_car_type === "medio" ? "active" : ""}`} onClick={() => this.selectCarType("medio")}>
          <span className="lavery-view-home-car-icon"></span>
          <span className="lavery-view-home-car-title">Carro Médio</span>
          <span className="lavery-view-home-car-checked"></span>
        </li>
        <li className={`lavery-view-home-car grande ${this.state.selected_car_type === "grande" ? "active" : ""}`} onClick={() => this.selectCarType("grande")}>
          <span className="lavery-view-home-car-icon"></span>
          <span className="lavery-view-home-car-title">Carro Grande</span>
          <span className="lavery-view-home-car-checked"></span>
        </li>
        <li className={`lavery-view-home-car extragrande ${this.state.selected_car_type === "extragrande" ? "active" : ""}`} onClick={() => this.selectCarType("extragrande")}>
          <span className="lavery-view-home-car-icon"></span>
          <span className="lavery-view-home-car-title">Carro Extra-grande</span>
          <span className="lavery-view-home-car-checked"></span>
        </li>
      </ul>
      <Submit
        label="Escolha o tipo de lavagem"
        active={this.state.selected_car_type !== null}
        onSubmit={() => this.onSubmit()}
      />
    </div>);
  }
}