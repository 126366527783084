import React, {Component} from 'react';
import './washes.css';

import {
  Header,
  Submit,
  StepLabel
} from '../../components';

export default class ViewWashes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "selected_wash_type": null,
      "current_price": null
    }
  }

  componentDidMount() {

  }

  GETPrices() {
    return {
      "mini": {
        "ducha-pretinho": 10.00,
        "ducha-pretinho-secagem": 15.00,
        "lavagem-simples": 29.99,
        "lavagem-simples-cera": 49.99,
        "lavagem-completa": 49.99,
        "lavagem-completa-cera": 69.99
      },
      "pequeno": {
        "ducha-pretinho": 15.00,
        "ducha-pretinho-secagem": 20.00,
        "lavagem-simples": 34.99,
        "lavagem-simples-cera": 54.99,
        "lavagem-completa": 54.99,
        "lavagem-completa-cera": 74.99
      },
      "medio": {
        "ducha-pretinho": 20.00,
        "ducha-pretinho-secagem": 25.00,
        "lavagem-simples": 39.99,
        "lavagem-simples-cera": 59.99,
        "lavagem-completa": 59.99,
        "lavagem-completa-cera": 79.99
      },
      "grande": {
        "ducha-pretinho": 25.00,
        "ducha-pretinho-secagem": 30.00,
        "lavagem-simples": 44.99,
        "lavagem-simples-cera": 64.99,
        "lavagem-completa": 64.99,
        "lavagem-completa-cera": 84.99
      },
      "extragrande": {
        "ducha-pretinho": 30.00,
        "ducha-pretinho-secagem": 35.00,
        "lavagem-simples": 49.99,
        "lavagem-simples-cera": 69.99,
        "lavagem-completa": 69.99,
        "lavagem-completa-cera": 89.99
      }
    }
  }

  selectWashType(type) {
    this.setState({
      "selected_wash_type": type
    }, function() {
      this.setState({
        "current_price": this.getServicePrice()
      });
    });
  }

  getServicePrice() {
    var prices = this.GETPrices();
    var currentCarTypePrices = prices[this.props.match.params.type];
    var price = currentCarTypePrices[this.state.selected_wash_type];
    return price;
  }

  onBack() {
    window.location = window.location.origin + "/#/";
  }

  render() {
    return (<div className="lavery-view-washes">
      <Header
        price={this.state.current_price}
        onBack={() => this.onBack()}
      />
      <h1></h1>
      <StepLabel
        step={2}
        label="Selecione a lavagem desejada"
      />
      <ul className="lavery-view-washes-types">
        <li className={`lavery-view-washes-type ducha-pretinho ${this.state.selected_wash_type === "ducha-pretinho" ? "active" : ""}`} onClick={() => this.selectWashType("ducha-pretinho")}>
          <span className="lavery-view-washes-type-icon"></span>
          <span className="lavery-view-washes-type-title">Ducha c/ pretinho</span>
          <span className="lavery-view-washes-type-checked"></span>
        </li>
        <li className={`lavery-view-washes-type ducha-pretinho-secagem ${this.state.selected_wash_type === "ducha-pretinho-secagem" ? "active" : ""}`} onClick={() => this.selectWashType("ducha-pretinho-secagem")}>
          <span className="lavery-view-washes-type-icon"></span>
          <span className="lavery-view-washes-type-title">Ducha c/ pretinho + secagem</span>
          <span className="lavery-view-washes-type-checked"></span>
        </li>
        <li className={`lavery-view-washes-type lavagem-simples ${this.state.selected_wash_type === "lavagem-simples" ? "active" : ""}`} onClick={() => this.selectWashType("lavagem-simples")}>
          <span className="lavery-view-washes-type-icon"></span>
          <span className="lavery-view-washes-type-title">Lavagem simples</span>
          <span className="lavery-view-washes-type-checked"></span>
        </li>
        <li className={`lavery-view-washes-type lavagem-simples-cera ${this.state.selected_wash_type === "lavagem-simples-cera" ? "active" : ""}`} onClick={() => this.selectWashType("lavagem-simples-cera")}>
          <span className="lavery-view-washes-type-icon"></span>
          <span className="lavery-view-washes-type-title">Lavagem simples + cera</span>
          <span className="lavery-view-washes-type-checked"></span>
        </li>
        <li className={`lavery-view-washes-type lavagem-completa ${this.state.selected_wash_type === "lavagem-completa" ? "active" : ""}`} onClick={() => this.selectWashType("lavagem-completa")}>
          <span className="lavery-view-washes-type-icon"></span>
          <span className="lavery-view-washes-type-title">Lavagem completa</span>
          <span className="lavery-view-washes-type-checked"></span>
        </li>
        <li className={`lavery-view-washes-type lavagem-completa-cera ${this.state.selected_wash_type === "lavagem-completa-cera" ? "active" : ""}`} onClick={() => this.selectWashType("lavagem-completa-cera")}>
          <span className="lavery-view-washes-type-icon"></span>
          <span className="lavery-view-washes-type-title">Lavagem completa + cera</span>
          <span className="lavery-view-washes-type-checked"></span>
        </li>
      </ul>
      <Submit
        label="Escolha os serviços adicionais"
        active={this.state.selected_wash_type !== null}
      />
    </div>);
  }
}
