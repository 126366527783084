import React, { Component } from 'react';
import './step-label.css';

export default class StepLabel extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit() {
    if (this.props.active) this.props.onSubmit();
  }

  render() {
    return (
      <div className="lavery-component-step-label">
        <span className="lavery-component-step-label-step">{this.props.step}</span>
        <h1 className="lavery-component-step-label-text">{this.props.label}</h1>
      </div>
    );
  }
}
