import React, { Component } from 'react';
import './submit.css';

export default class Submit extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit() {
    if (this.props.active) this.props.onSubmit();
  }

  render() {
    return (
      <div className="lavery-component-submit">
        <button onClick={() => this.onSubmit()} className={`lavery-component-submit-btn ${this.props.active ? "active" : ""}`}>{this.props.label}</button>
      </div>
    );
  }
}
