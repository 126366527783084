import React, { Component } from 'react';
import Logo from './logo.png';
import './header.css';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.openHome = this.openHome.bind(this);
  }
  
  openHome() {
    window.location = window.location.origin + "/#/";
  }

  render() {
    return (
      <div className="lavery-component-header">
        {this.props.onBack !== undefined &&
          <span className="lavery-component-header-back" onClick={() => this.props.onBack()}></span>
        }
        <img className="lavery-component-header-logo" src={Logo} onClick={() => this.openHome()}/>
        {this.props.price !== undefined && this.props.price !== null &&
          <span className="lavery-componente-header-price">R${this.props.price}</span>
        }
      </div>
    );
  }
}
